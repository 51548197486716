<template>
  <div>
    <a-modal v-model:visible="dialogVisible"
             @ok="exportBtn" @cancel ="handleClose">
      <a-form :model="form"
              size="medium"
              ref="formRef"
              label-width="100rem">

        <a-form-item>
          <a-checkbox :indeterminate="indeterminate"
                      :checked="checkAll"
                      @change="handleCheckAllChange">
            全选
          </a-checkbox>

          <a-checkbox-group :options="cityOptions"
                            :value="checkedList"
                            @change="onChange" />

        </a-form-item>

      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { getExplainReport } from '@/api/home.js'
import { getUserSubject } from '@/api/subject.js'
import { saveAs } from "file-saver"


export default {
  data () {
    return {
      dialogVisible: false,
      cityOptions: [],
      form: {
      },
      has_explain: 1,
      checkAll: false,
      checkedCities: [],
      checkedList: [],
      select_all: 0,
      indeterminate: false,

    }
  },
  async created () {
    const { data } = await getUserSubject()
    this.cityOptions = data.list.map(item =>
      item.subject_name,

    );
    this.cityOptionsCopy = data.list.map(item => {
      return {
        subject_id: item.subject_id,
        subject_name: item.subject_name,
      }
    });
  
  },
  methods: {
   
    onChange (checkedList, cityOptions) {

      this.indeterminate = !!checkedList.length && checkedList.length < this.cityOptions.length;
      this.checkAll = checkedList.length === this.cityOptions.length;
      this.checkedList = JSON.parse(JSON.stringify(checkedList))
    },
    handleCheckAllChange (e) {
      Object.assign(this, {
        checkedList: e.target.checked ? this.cityOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      if (e.target.checked) {

        this.select_all = 1
      }
      else {

        this.select_all = 0
      }
    },

    handleClose () {
      this.dialogVisible = false
     this.checkedList = []
    },
    exportBtn () {
      console.log('this.checkedList', this.checkedList)
      let exportArr = []
      this.cityOptionsCopy.forEach(element => {
        this.checkedList.forEach(item => {
          if (element.subject_name == item) {
            exportArr.push(element)
          }
        })
      });
      console.log('exportArr', exportArr)
      exportArr.forEach(item => {
        this.postExport(item.subject_id, item.subject_name)
      })
      this.handleClose()
      
    },
    base64ToBlob (base64) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: 'application/octet-stream' });
    },

    async postExport (subject_id, subject_name) {
     
      this.$parent.btnDisabled = true


      await getExplainReport({ has_explain: this.has_explain, subject_id, select_all: this.select_all }).then(res => {
        const a = document.createElement("a")
        const blob = this.base64ToBlob(res.data.base64Content)
        console.log('blob', blob)
        a.href = window.URL.createObjectURL(blob)


        if (this.checkAll) {
          if (this.has_explain == 1) {

            a.download = `${subject_name}教辅.docx`
            a.click()
          } else {
            a.download = `${subject_name}题集.docx`
            a.click()

          }
        } else {
          if (this.has_explain == 1) {
            a.download = `${subject_name}教辅.docx`
            a.click()
          } else {
            a.download = `${subject_name}题集.docx`
            a.click()
          }
        }
        this.$parent.btnDisabled = false


      })

      // const data = await getExplainReport({ has_explain: this.has_explain, subject_id, select_all: this.select_all })
      // if (data.size > 0) {
      //   this.$parent.btnDisabled = false
      // }
      // let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      // if (this.checkAll) {
      //   if (this.has_explain == 1) {
      //     saveAs(blob, `${subject_name}教辅.docx`)
      //   } else {
      //     saveAs(blob, `${subject_name}题集.docx`)
      //   }
      // } else {
      //   if (this.has_explain == 1) {
      //     saveAs(blob, `${subject_name}教辅.docx`)
      //   } else {
      //     saveAs(blob, `${subject_name}题集.docx`)
      //   }
      // }

    }
  }
}
</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23rem;
  font-size: 16rem;
  // padding: 27rem;
  padding-top: 0;
}
.btn_warp {
  width: 720rem;
  height: 62rem;
  border-top: 1rem solid #eeeeee;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0rem 0rem 0rem 0rem;
  opacity: 1;
  align-items: center;
  justify-content: center;

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // font-size: 24rem;
    &:nth-child(1) {
      width: 40rem;
      height: 60rem;
      font-size: 20rem;

      font-weight: 400;
      border-right: 1rem solid #eeeeee;
      color: #333333;

      line-height: 30rem;
      -webkit-background-clip: text;
    }
    &:nth-child(2) {
      width: 40rem;
      height: 30rem;

      font-size: 20rem;

      font-weight: 400;
      color: #2196f3;
      line-height: 30rem;
      -webkit-background-clip: text;
    }
  }
}
::v-deep .el-checkbox__inner::after {
  height: 15rem;
  left: 7rem;
  width: 5rem;
  box-sizing: content-box;
  content: "";
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;

  position: absolute;
  top: 1px;
  transform: rotate(45deg) scaleY(0);

  transition: transform 0.15s ease-in 0.05s;
  transform-origin: center;
}
::v-deep .el-dialog__body {
  padding: 0rem !important;
  height: 236rem;
  flex-wrap: wrap-reverse;
  display: flex;
  max-width: 790rem;
  margin-top: 4rem;
}

::v-deep .el-dialog__header {
  text-align: center;
  padding-left: 288rem;
  padding-top: 30rem;
  width: 144rem;
  height: 36rem;
  font-size: 24rem;

  font-weight: 500;
  color: #333333;
  line-height: 36rem;
  -webkit-background-clip: text;
}
::v-deep .el-dialog {
  border-radius: 10rem;
  width: 720rem;
  height: auto;
  background: #ffffff;
  border-radius: 24rem 24rem 24rem 24rem;
  opacity: 1;
}

::v-deep .el-textarea__inner {
  font-size: 16rem;
}
::v-deep .el-form-item__content {
  margin-left: 37rem !important;
}

::v-deep .el-input__inner {
  // height: 45rem;
  line-height: 45rem;
  font-size: 16rem;
}
::v-deep .el-checkbox__input {
  padding-top: 4rem;
}
::v-deep .el-form-item__label {
  line-height: 45rem;
  font-size: 18rem;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  display: none;
}
::v-deep .el-dialog__title {
  width: 144rem;
  height: 36rem;
  font-size: 24rem;

  font-weight: 600;
  color: #333333;
  line-height: 36rem;
  -webkit-background-clip: text;
}

::v-deep .el-checkbox-group {
  text-align: left; //这个很关键，否则最后一行是居中状态
}
::v-deep .el-checkbox__inner {
  width: 23rem;
  height: 23rem;
}
::v-deep .el-checkbox__label {
  font-size: 20rem;
  padding-left: 8rem;
}
::v-deep .el-checkbox {
  // margin-left: 20rem !important;
  margin-right: 24rem;
  // width: 72rem; //根据内容设置宽度
  height: 30rem;
  // padding-left: 0rem;
  font-size: 20rem;
  margin-bottom: 24rem;
  text-align: left; //这个很关键，否则每一行都是居中状态
}
</style>